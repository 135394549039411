import { library, dom  }   from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faAlignCenter,
  faAlignLeft,
  faArrowDown,
  faArrowDownShortWide,
  faArrowLeft,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faArrowRotateLeft,
  faArrowUp,
  faArrowUpRightFromSquare,
  faAsterisk,
  faAt,
  faBan,
  faBarcode,
  faBars,
  faBrazilianRealSign,
  faBook,
  faBorderAll,
  faBoxArchive,
  faBuilding,
  faBuildingColumns,
  faBullhorn,
  faCakeCandles,
  faCalendarAlt,
  faCalendarDay,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faChartColumn,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleCheck,
  faCircleInfo,
  faCirclePlay,
  faCirclePlus,
  faCircleStop,
  faCircleQuestion,
  faCircleUser,
  faCircleXmark,
  faClockRotateLeft,
  faCloud,
  faCloudArrowDown,
  faCloudArrowUp,
  faCog,
  faComment,
  faComments,
  faCopy,
  faCubes,
  faDesktop,
  faDollarSign,
  faDownLeftAndUpRightToCenter,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFilter,
  faFolderOpen,
  faGavel,
  faGear,
  faGift,
  faGraduationCap,
  faHandFist,
  faHandHoldingDollar,
  faHashtag,
  faInbox,
  faInfoCircle,
  faLeftLong,
  faLink,
  faList,
  faListCheck,
  faListUl,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMinus,
  faMoneyBillTransfer,
  faNoteSticky,
  faPalette,
  faPaperclip,
  faPause,
  faPeopleGroup,
  faPenToSquare,
  faPencil,
  faPencilAlt,
  faPercent,
  faPerson,
  faPersonChalkboard,
  faPhone,
  faPlay,
  faPlayCircle,
  faPlus,
  faPrint,
  faPuzzlePiece,
  faQrcode,
  faQuestionCircle,
  faReply,
  faRetweet,
  faRightFromBracket,
  faRightToBracket,
  faRightLeft,
  faRotate,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faShare,
  faShareAlt,
  faShareNodes,
  faSignature,
  faSignOutAlt,
  faSitemap,
  faSort,
  faSpellCheck,
  faSpinner,
  faSquare,
  faSquareCheck,
  faStar,
  faStopCircle,
  faTable,
  faTableColumns,
  faTag,
  faTags,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashCan,
  faTriangleExclamation,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserPlus,
  faUserXmark,
  faUsers,
  faUsersViewfinder,
  faUserTie,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import {
  faBell as frBell,
  faBuilding as frBuilding,
  faCalendar as frCalendar,
  faCalendarCheck as frCalendarCheck,
  faCalendarDays as frCalendarDays,
  faCheckCircle as frCheckCircle,
  faCircle as frCircle,
  faCircleCheck as frCircleCheck,
  faCircleDot as frCircleDot,
  faCircleDown as frCircleDown,
  faCircleUser as frCircleUser,
  faCircleXmark as frCircleXmark,
  faClipboard as frClipboard,
  faClock as frClock,
  faComment as frComment,
  faCommentDots as frCommentDots,
  faCopy as frCopy,
  faEnvelope as frEnvelope,
  faEye as frEye,
  faFile as frFile,
  faFileAlt as frFileAlt,
  faFileAudio as frFileAudio,
  faFileCode as frFileCode,
  faFileExcel as frFileExcel,
  faFileImage as frFileImage,
  faFileLines as frFileLines,
  faFilePdf as frFilePdf,
  faFilePowerpoint as frFilePowerpoint,
  faFileVideo as frFileVideo,
  faFileWord as frFileWord,
  faFileZipper as frFileZipper,
  faFolder as frFolder,
  faFolderOpen as frFolderOpen,
  faImage as frImage,
  faMoneyBill1 as frMoneyBill1,
  faNoteSticky as frNoteSticky,
  faPenToSquare as frPenToSquare,
  faSquare as frSquare,
  faSquareCheck as frSquareCheck,
  faSquareCaretRight as frSquareCaretRight,
  faThumbsDown as frThumbsDown,
  faTimesCircle as frTimesCircle,
  faTrashCan as frTrashCan,
  faUserCircle as frUserCircle
} from '@fortawesome/free-regular-svg-icons';

import {
  faDelicious,
  faDiscord,
  faFacebookMessenger,
  faFlickr,
  faFoursquare,
  faGoogle,
  faInstagram,
  faLinkedin,
  faQq,
  faSkype,
  faSquareFacebook,
  faSquareGooglePlus,
  faSquareLastfm,
  faSquarePinterest,
  faSquareReddit,
  faSquareSnapchat,
  faSquareThreads,
  faSquareTumblr,
  faSquareXTwitter,
  faSquareVimeo,
  faSquareYoutube,
  faTelegram,
  faTiktok,
  faWeibo,
  faWeixin,
  faWhatsapp,
  faYelp
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faAlignCenter,
  faAlignLeft,
  faArrowDown,
  faArrowDownShortWide,
  faArrowLeft,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faArrowRotateLeft,
  faArrowUp,
  faArrowUpRightFromSquare,
  faAsterisk,
  faAt,
  faBan,
  faBarcode,
  faBars,
  faBrazilianRealSign,
  faBook,
  faBorderAll,
  faBoxArchive,
  faBuilding,
  faBuildingColumns,
  faBullhorn,
  faCakeCandles,
  faCalendarAlt,
  faCalendarDay,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faChartColumn,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleCheck,
  faCircleInfo,
  faCirclePlay,
  faCirclePlus,
  faCircleStop,
  faCircleQuestion,
  faCircleUser,
  faCircleXmark,
  faClockRotateLeft,
  faCloud,
  faCloudArrowDown,
  faCloudArrowUp,
  faCog,
  faComment,
  faComments,
  faCopy,
  faCubes,
  faDesktop,
  faDollarSign,
  faDownLeftAndUpRightToCenter,
  faDownload,
  faEllipsisH,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFilter,
  faFolderOpen,
  faGavel,
  faGear,
  faGift,
  faGraduationCap,
  faHandFist,
  faHandHoldingDollar,
  faHashtag,
  faInbox,
  faInfoCircle,
  faLeftLong,
  faLink,
  faList,
  faListCheck,
  faListUl,
  faLocationDot,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMinus,
  faMoneyBillTransfer,
  faNoteSticky,
  faPalette,
  faPaperclip,
  faPause,
  faPercent,
  faPeopleGroup,
  faPenToSquare,
  faPencil,
  faPencilAlt,
  faPerson,
  faPersonChalkboard,
  faPhone,
  faPlay,
  faPlayCircle,
  faPlus,
  faPrint,
  faPuzzlePiece,
  faQrcode,
  faQuestionCircle,
  faReply,
  faRetweet,
  faRightFromBracket,
  faRightToBracket,
  faRightLeft,
  faRotate,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faShare,
  faShareAlt,
  faShareNodes,
  faSignature,
  faSignOutAlt,
  faSitemap,
  faSort,
  faSpellCheck,
  faSpinner,
  faSquare,
  faSquareCheck,
  faStar,
  faStopCircle,
  faTable,
  faTableColumns,
  faTag,
  faTags,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashCan,
  faTriangleExclamation,
  faUpload,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserPlus,
  faUserXmark,
  faUsers,
  faUsersViewfinder,
  faUserTie,
  faXmark,
  frBell,
  frBuilding,
  frCalendar,
  frCalendarCheck,
  frCalendarDays,
  frCheckCircle,
  frCircle,
  frCircleDot,
  frCircleCheck,
  frCircleDown,
  frCircleUser,
  frCircleXmark,
  frClipboard,
  frClock,
  frComment,
  frCommentDots,
  frCopy,
  frEnvelope,
  frEye,
  frFile,
  frFileAlt,
  frFileAudio,
  frFileCode,
  frFileExcel,
  frFileImage,
  frFileLines,
  frFilePdf,
  frFilePowerpoint,
  frFileVideo,
  frFileWord,
  frFileZipper,
  frFolder,
  frFolderOpen,
  frImage,
  frMoneyBill1,
  frNoteSticky,
  frPenToSquare,
  frSquare,
  frSquareCheck,
  frSquareCaretRight,
  frThumbsDown,
  frTimesCircle,
  frTrashCan,
  frUserCircle,
  faDelicious,
  faDiscord,
  faFacebookMessenger,
  faFlickr,
  faFoursquare,
  faGoogle,
  faInstagram,
  faLinkedin,
  faQq,
  faSkype,
  faSquareFacebook,
  faSquareGooglePlus,
  faSquareLastfm,
  faSquarePinterest,
  faSquareReddit,
  faSquareSnapchat,
  faSquareThreads,
  faSquareTumblr,
  faSquareXTwitter,
  faSquareVimeo,
  faSquareYoutube,
  faTelegram,
  faTiktok,
  faWeibo,
  faWeixin,
  faWhatsapp,
  faYelp
);

export { FontAwesomeIcon };

dom.watch();
